@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro");

body .nowplayingcard {
  min-width: 200px;
  max-width: 20%;
  margin: 0 auto;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
}
body .nowplayingcard .nowplayingcontainer-inner {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  display: inline-block;
  -webkit-border-top-left-radius: 3px;
  -moz-border-top-left-radius: 3px;
  -ms-border-top-left-radius: 3px;
  -o-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-bottom-left-radius: 3px;
  -ms-border-bottom-left-radius: 3px;
  -o-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .nowplayingcard .nowplayingcontainer-inner:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
body .nowplayingcard .nowplayingcontainer-inner img#trackart {
  max-width: 30%;
  float: left;
  left: 0;
  -webkit-border-top-left-radius: 3px;
  -moz-border-top-left-radius: 3px;
  -ms-border-top-left-radius: 3px;
  -o-border-top-left-radius: 3px;
  border-top-left-radius: 3px;
  -webkit-border-bottom-left-radius: 3px;
  -moz-border-bottom-left-radius: 3px;
  -ms-border-bottom-left-radius: 3px;
  -o-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
body .nowplayingcard .nowplayingcontainer-inner .trackInfo {
  width: 70%;
  float: left;
  display: block;
}
body .nowplayingcard .nowplayingcontainer-inner .trackInfo a {
  max-width: 90%;
  display: block;
  font-size: 14px;
  text-align: left;
  text-decoration: none;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
body .nowplayingcard .nowplayingcontainer-inner .trackInfo a:nth-child(odd) {
  color: black;
  font-weight: bold;
  vertical-align: middle;
  line-height: 15px;
  letter-spacing: 0.2px;
  padding: 10% 0 0 5%;
}
body
  .nowplayingcard
  .nowplayingcontainer-inner
  .trackInfo
  a:nth-child(odd)
  img {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  margin: -2% 3px 0 0;
}
body .nowplayingcard .nowplayingcontainer-inner .trackInfo a:nth-child(even) {
  color: gray;
  font-size: 12px;
  letter-spacing: 0.1px;
  padding: 5% 0 0 5%;
}
body
  .nowplayingcard
  .nowplayingcontainer-inner
  .trackInfo
  a:nth-child(even)
  img {
  width: 15px;
  height: 15px;
  vertical-align: middle;
  margin: -2% 3px 0 0;
}