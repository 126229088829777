.menu-background {
  &::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(152, 66, 211, 0.63);
  }
}

.VIP-style {
  
}